import { tableAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

import getDefaultTransitionProps from '../utils/getDefaultTransitionProps';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const variantSimple = definePartsStyle((props) => {
  const transitionProps = getDefaultTransitionProps();

  return {
    th: {
      ...transitionProps,
      color: mode('neutral.light.5', 'whiteAlpha.700')(props),
      backgroundColor: mode('white', 'whiteAlpha.200')(props),
      border: '1px solid #EDEBEC',
      borderLeftWidth: 0,
      fontWeight: 500,
      borderRightWidth: 0,
      padding: {
        base: '0.75rem 0 0.75rem 1rem',
        xl: '0.75rem 0 0.75rem 1.5rem',
      },
      _first: {
        borderTopLeftRadius: '8px',
        borderLeftWidth: 1,
        borderRight: 0,
      },
      _last: {
        borderTopRightRadius: '8px',
        borderRightWidth: 1,
        borderLeftWidth: 0,
        padding: {
          base: '0.75rem 1rem 0.75rem 1rem',
          xl: '0.75rem 1.5rem 0.75rem 1.5rem',
        },
      },
    },
    thead: {
      ...transitionProps,
    },
    td: {
      ...transitionProps,
      border: '1px solid #EDEBEC',
      borderTopWidth: 1,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      // Sx: {
      //   paddingX: {
      //     base: "1rem !important",
      //     xl: "1.5rem !important",
      //   },
      //   paddingY: "0.75rem !important",
      // },
      padding: {
        base: '0.75rem 0 0.75rem 1rem',
        xl: '0.75rem 0 0.75rem 1.5rem',
      },
      _first: {
        borderLeftWidth: 1,
      },
      _last: {
        borderRightWidth: 1,
        padding: {
          base: '0.75rem 1rem 0.75rem 1rem',
          xl: '0.75rem 1.5rem 0.75rem 1.5rem',
        },
      },
    },
    tr: {
      ...transitionProps,
      _first: {
        td: {
          border: '1px solid #EDEBEC',
          borderWidth: 0,
          _first: {
            borderLeftWidth: 1,
          },
          _last: {
            borderRightWidth: 1,
          },
        },
      },
      _last: {
        td: {
          border: '1px solid #EDEBEC',
          borderTopWidth: 1,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderBottomWidth: 1,
          _first: {
            borderBottomLeftRadius: '8px',
            borderLeftWidth: 1,
          },
          _last: {
            borderBottomRightRadius: '8px',
            borderRightWidth: 1,
          },
        },
      },
    },
  };
});

const sizes = {
  md: definePartsStyle({
    th: {
      px: 4,
      fontSize: 'sm',
    },
    td: {
      p: 4,
    },
  }),
  sm: definePartsStyle({
    th: {
      px: '10px',
      py: '10px',
      fontSize: 'sm',
    },
    td: {
      px: '10px',
      py: 4,
      fontSize: 'sm',
      fontWeight: 500,
    },
  }),
  xs: definePartsStyle({
    th: {
      px: '6px',
      py: '10px',
      fontSize: 'sm',
    },
    td: {
      px: '6px',
      py: 4,
      fontSize: 'sm',
      fontWeight: 500,
    },
  }),
};

const variants = {
  simple: variantSimple,
};

// Const border = "1px solid";
// const borderColor = "neutral.light.3";

const baseStyle = definePartsStyle({
  th: {
    textTransform: 'none',
    fontFamily: 'body',
    fontWeight: 500,
    overflow: 'hidden',
    color: 'neutral.light.5',
    letterSpacing: 'none',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontStyle: 'normal',
    verticalAlign: 'middle',
  },
  td: {
    verticalAlign: 'middle',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
    color: 'neutral.light.7',
  },
  thead: {
    lineHeight: '1.25rem',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  table: {
    minWidth: { base: '1324px', xl: '100%' },
    tableLayout: 'fixed',
    overflow: 'unset',
    fontVariant: 'normal',
    fontVariantLigatures: 'no-contextual',
    borderCollapse: 'separate',
    borderSpacing: 0,
    bgColor: 'neutral.light.1',
    // MarginTop: "10px",
  },
  tbody: {
    tr: {
      _hover: {
        backgroundColor: 'primary.light.1',
        cursor: 'pointer',
      },
    },
  },
});

const Table = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
});

export default Table;
