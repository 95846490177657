import { Text } from '@chakra-ui/react';
import { AgreeToTermsPolicy, SignInMode } from '@logto/schemas';
import { type TFuncKey } from 'i18next';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';

import LandingPageLayout from '@/Layout/LandingPageLayout';
import SingleSignOnFormModeContextProvider from '@/Providers/SingleSignOnFormModeContextProvider';
import SingleSignOnFormModeContext from '@/Providers/SingleSignOnFormModeContextProvider/SingleSignOnFormModeContext';
import Divider from '@/components/Divider';
import GoogleOneTap from '@/components/GoogleOneTap';
import TextLink from '@/components/TextLink';
import SocialSignInList from '@/containers/SocialSignInList';
import TermsAndPrivacyCheckbox from '@/containers/TermsAndPrivacyCheckbox';
import TermsAndPrivacyLinks from '@/containers/TermsAndPrivacyLinks';
import { useSieMethods } from '@/hooks/use-sie';
import useTerms from '@/hooks/use-terms';

import ErrorPage from '../ErrorPage';

import Main from './Main';
import styles from './index.module.scss';

const SignInFooters = () => {
  const { t } = useTranslation();
  const { termsValidation, agreeToTermsPolicy } = useTerms();
  const navigate = useNavigate();

  const { signInMethods, signUpMethods, socialConnectors, signInMode, singleSignOnEnabled } =
    useSieMethods();

  const { showSingleSignOnForm } = useContext(SingleSignOnFormModeContext);

  const handleSsoNavigation = useCallback(async () => {
    /**
     * Check if the user has agreed to the terms and privacy policy before navigating to the SSO page
     * when the policy is set to `Manual`
     */
    if (agreeToTermsPolicy === AgreeToTermsPolicy.Manual && !(await termsValidation())) {
      return;
    }
    navigate('/single-sign-on/email');
  }, [agreeToTermsPolicy, navigate, termsValidation]);

  /* Hide footers when showing Single Sign On form */
  if (showSingleSignOnForm) {
    return null;
  }

  const singleSignOnFooterRender = () => {
    if (!singleSignOnEnabled) {
      return null;
    }
    return (
      <>
        <div className={styles.singleSignOn}>
          {t('description.use')}{' '}
          <TextLink text={'action.single_sign_on' as TFuncKey} onClick={handleSsoNavigation} />
        </div>
        {
          /**
           * If only SSO sign-in methods are available, display the agreement checkbox when the agreement policy is `Manual`.
           */
          signInMethods.length === 0 &&
            socialConnectors.length === 0 &&
            agreeToTermsPolicy === AgreeToTermsPolicy.Manual && (
              <TermsAndPrivacyCheckbox className={styles.checkboxForSsoOnly} />
            )
        }
      </>
    );
  };

  const createAccountFooterRender = () => {
    if (signInMode === SignInMode.SignInAndRegister && signUpMethods.length > 0) {
      return (
        <div className={styles.createAccount}>
          <Text as="span" color="neutral.light.7">{`Haven't had account?`}</Text>{' '}
          <TextLink replace to="/register" normalText="Register" />
        </div>
      );
    }
  };

  return (
    <>
      {singleSignOnFooterRender()}
      {createAccountFooterRender()}
      {
        // Social sign-in methods
        signInMethods.length > 0 && socialConnectors.length > 0 && (
          <>
            <Divider label="description.or" className={styles.divider} />
            <SocialSignInList socialConnectors={socialConnectors} className={styles.main} />
          </>
        )
      }
    </>
  );
};

const SignIn = () => {
  const { signInMethods, socialConnectors, signInMode } = useSieMethods();
  const { agreeToTermsPolicy } = useTerms();

  if (!signInMode) {
    return <ErrorPage />;
  }

  if (signInMode === SignInMode.Register) {
    return <Navigate to="/register" />;
  }

  return (
    <LandingPageLayout
      title="description.sign_in_to_your_account"
      titleDescription={
        'Sign In to to manage your profile, personal tags, watchlist more' as string
      }
    >
      <GoogleOneTap context="signin" />
      <SingleSignOnFormModeContextProvider>
        <Main signInMethods={signInMethods} socialConnectors={socialConnectors} />
        <SignInFooters />
      </SingleSignOnFormModeContextProvider>
      {
        // Only show terms and privacy links for sign in page if the agree to terms policy is `Automatic` or `ManualRegistrationOnly`
        agreeToTermsPolicy !== AgreeToTermsPolicy.Manual && (
          <TermsAndPrivacyLinks className={styles.terms} />
        )
      }
    </LandingPageLayout>
  );
};

export default SignIn;
