import { Box, Text, Image, Flex } from '@chakra-ui/react';
import type { Nullable } from '@silverhand/essentials';
import type { TFuncKey } from 'i18next';

import SeiLogo from '@/assets/sei.png';

import DynamicT from '../DynamicT';

export type Props = {
  readonly className?: string;
  readonly logo?: Nullable<string>;
  readonly thirdPartyLogo?: Nullable<string>;
  readonly headline?: TFuncKey;
  readonly headlineDescription?: TFuncKey | string;
  readonly headlineInterpolation?: Record<string, unknown>;
};

const BrandingHeader = ({ headline, headlineDescription, headlineInterpolation }: Props) => {
  return (
    <Flex flexDirection="column">
      <Image src={SeiLogo} w="auto" h="52px" maxW="52px" mx="auto" />
      {headline && (
        <Box mt={4}>
          <Text fontSize="28px" fontWeight={600} color="neutral.light.8" textAlign="center">
            <DynamicT forKey={headline} interpolation={headlineInterpolation} />
          </Text>
          {headlineDescription && (
            <Text textAlign="center" fontSize="16px" color="neutral.light.7">
              {typeof headlineDescription === 'string' ? (
                headlineDescription
              ) : (
                <DynamicT forKey={headlineDescription} />
              )}
            </Text>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default BrandingHeader;
