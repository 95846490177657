import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  borderRadius: '0.5rem',
  padding: { base: 4, lg: 5 },
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
  color: 'neutral.light.7',
  borderWidth: '1px',
  borderColor: 'neutral.light.3',
  boxShadow: 'unset',
  maxHeight: '300px',
});

const Code = defineStyleConfig({
  variants: { outline },
});

export default Code;
