import { tagAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

import getDefaultTransitionProps from '../../utils/getDefaultTransitionProps';
import Badge from '../Badge';

const transitionProps = getDefaultTransitionProps();

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const variants = {
  subtle: definePartsStyle((props) => {
    let theme = {
      border: '0.5px solid',
      borderColor: 'transparent',
      ...Badge.variants?.subtle(props),
    };

    if (props.colorScheme === 'green') {
      theme = Object.assign(theme, {
        _groupHover: {
          borderColor: 'secondary.02',
        },
        bgColor: 'secondary.02.bg',
        color: 'secondary.02.text',
      });
    }

    if (props.colorScheme === 'red') {
      theme = Object.assign(theme, {
        _groupHover: {
          borderColor: 'secondary.05',
        },
        bgColor: 'secondary.05.bg',
        color: 'secondary.05.text',
      });
    }

    if (props.colorScheme === 'orange') {
      theme = Object.assign(theme, {
        _groupHover: {
          borderColor: 'secondary.01',
        },
        bgColor: 'secondary.01.bg',
        color: 'secondary.01.text',
      });
    }

    if (props.colorScheme === 'purple') {
      theme = Object.assign(theme, {
        _groupHover: {
          borderColor: 'secondary.03.text',
        },
        bgColor: 'secondary.03.bg',
        color: 'secondary.03.text',
      });
    }

    if (props.colorScheme === 'gray') {
      theme = Object.assign(theme, {
        _groupHover: {
          borderColor: 'secondary.06',
        },
        bgColor: 'secondary.06.bg',
        color: 'secondary.06.text',
      });
    }

    if (props.colorScheme === 'blue' || props.colorScheme === 'cyan') {
      theme = Object.assign(theme, {
        _groupHover: {
          borderColor: 'secondary.04',
        },
        bgColor: 'secondary.04.bg',
        color: 'secondary.04.text',
      });
    }

    return {
      container: theme,
    };
  }),
  outline: definePartsStyle((props) => {
    let theme = {
      borderWidth: '0.5px',
      outlineWidth: '0px',
      boxShadow: 'unset',
    };

    if (props.colorScheme === 'green') {
      theme = Object.assign(theme, {
        borderColor: 'secondary.02',
        bgColor: 'secondary.02.bg',
        color: 'secondary.02.text',
      });
    }

    if (props.colorScheme === 'red') {
      theme = Object.assign(theme, {
        borderColor: 'secondary.05',
        bgColor: 'secondary.05.bg',
        color: 'secondary.05.text',
      });
    }

    if (props.colorScheme === 'orange') {
      theme = Object.assign(theme, {
        borderColor: 'secondary.01',
        bgColor: 'secondary.01.bg',
        color: 'secondary.01.text',
      });
    }

    if (props.colorScheme === 'purple') {
      theme = Object.assign(theme, {
        borderColor: 'secondary.03.text',
        bgColor: 'secondary.03.bg',
        color: 'secondary.03.text',
      });
    }

    if (props.colorScheme === 'gray') {
      theme = Object.assign(theme, {
        borderColor: 'secondary.06',
        bgColor: 'secondary.06.bg',
        color: 'secondary.06.text',
      });
    }

    if (props.colorScheme === 'cyan') {
      theme = Object.assign(theme, {
        borderColor: 'secondary.04',
        bgColor: 'secondary.04.bg',
        color: 'secondary.04.text',
      });
    }

    if (props.colorScheme === 'red_primary') {
      theme = Object.assign(theme, {
        borderColor: 'primary.light.2',
        bgColor: 'primary.light.1',
        color: 'primary.light.4',
      });
    }

    return {
      container: theme,
    };
  }),
  unborder: definePartsStyle((props) => {
    let theme = {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 6,
    };

    if (props.colorScheme === 'green') {
      theme = Object.assign(theme, {
        bgColor: 'secondary.02.bg',
        color: 'secondary.02.text',
      });
    }

    if (props.colorScheme === 'red') {
      theme = Object.assign(theme, {
        bgColor: 'secondary.05.bg',
        color: 'secondary.05.text',
      });
    }

    if (props.colorScheme === 'orange') {
      theme = Object.assign(theme, {
        bgColor: 'secondary.01.bg',
        color: 'secondary.01.text',
      });
    }

    if (props.colorScheme === 'purple') {
      theme = Object.assign(theme, {
        bgColor: 'secondary.03.bg',
        color: 'secondary.03.text',
      });
    }
    if (props.colorScheme === 'gray') {
      theme = Object.assign(theme, {
        bgColor: 'secondary.06.bg',
        color: 'secondary.06.text',
      });
    }

    return {
      container: theme,
    };
  }),
  solid: definePartsStyle((props) => {
    let theme = {
      ...Badge.variants?.subtle(props),
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    };

    if (props.colorScheme === 'green') {
      theme = Object.assign(theme, {
        bgColor: 'secondary.02.text',
        color: 'secondary.02.bg',
      });
    }

    if (props.colorScheme === 'red') {
      theme = Object.assign(theme, {
        bgColor: 'secondary.05.text',
        color: 'secondary.05.bg',
      });
    }

    if (props.colorScheme === 'orange') {
      theme = Object.assign(theme, {
        bgColor: 'secondary.01.text',
        color: 'secondary.01.bg',
      });
    }

    if (props.colorScheme === 'purple') {
      theme = Object.assign(theme, {
        bgColor: 'secondary.03.text',
        color: 'secondary.03.bg',
      });
    }

    return {
      container: theme,
    };
  }),
};

const sizes = {
  md: definePartsStyle({
    container: {
      minH: 6,
      minW: 6,
      fontSize: 'sm',
      px: 2,
      py: '2px',
      lineHeight: 5,
    },
  }),
};

const baseStyleContainer = defineStyle({
  display: 'inline-block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  paddingX: '0.375rem',
  paddingY: '0.125rem',
  borderRadius: 'sm',
  cursor: 'inherit',
  fontWeight: 400,
  ...transitionProps,
});

const baseStyle = definePartsStyle({
  container: baseStyleContainer,
});

const Tag = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    size: 'md',
    variant: 'subtle',
    colorScheme: 'gray',
  },
});

export default Tag;
