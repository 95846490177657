import { useEffect } from 'react';
import { Flex, Text } from '@chakra-ui/react';

const WORKER_RESOURCES = [
  'https://seitrace.com',
  'https://develop.seitrace.com',
  'http://localhost:3000',
];

const AuthFooter = () => {
  useEffect(() => {
    const referrer = document.referrer;

    if (WORKER_RESOURCES.some((resource) => referrer.startsWith(resource))) {
      localStorage.setItem('referrer', referrer);
    }
  });

  return (
    <>
      <Flex mt={4} justifyContent="center" alignItems="center" gap="7px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 30 30"
          height="30px"
          width="30px"
        >
          <path
            fill="#4B66EC"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.204 17.204a1.126 1.126 0 0 0 0 1.591l6 6a1.125 1.125 0 0 0 1.591-1.59l-4.079-4.08H27a1.125 1.125 0 0 0 0-2.25H11.716l4.08-4.08a1.125 1.125 0 0 0-1.591-1.59z"
          />
        </svg>
        <Text
          cursor={'pointer'}
          onClick={() => {
            window.location.href = localStorage.getItem('referrer') || '/';
          }}
          textColor={'blue'}
          marginTop={'4px'}
          marginLeft={2}
          as={'div'}
        >
          Back Home
        </Text>
      </Flex>
    </>
  );
};

export default AuthFooter;
