const breakpoints = {
  // Maybe we need them in future
  sm: '375px',
  //   Md: '768px',
  lg: '1024px',
  '2lg': '1200px',
  xl: '1440px',
  // These breakpoint are needed just to make others work
  '2xl': '3000px',
};

export default breakpoints;
