import { z } from 'zod';
import { inSeconds } from './date.js';
export const tenantIdKey = 'tenant_id';
export const customClientMetadataDefault = Object.freeze({
    idTokenTtl: inSeconds.oneHour,
    refreshTokenTtlInDays: 14,
    rotateRefreshToken: true,
});
export var ExtraParamsKey;
(function (ExtraParamsKey) {
    /**
     * @deprecated Use {@link FirstScreen} instead.
     * @see {@link InteractionMode} for the available values.
     */
    ExtraParamsKey["InteractionMode"] = "interaction_mode";
    /**
     * The first screen to show for the user.
     *
     * @see {@link FirstScreen} for the available values.
     */
    ExtraParamsKey["FirstScreen"] = "first_screen";
    /**
     * Directly sign in via the specified method. Note that the method must be properly configured
     * in Seitrace.
     *
     * @remark
     * The format of the value for this key is one of the following:
     *
     * - `social:<target>` (Use a social connector with the specified target, e.g. `social:google`)
     * - `sso:<connector-id>` (Use the specified SSO connector, e.g. `sso:123456`)
     */
    ExtraParamsKey["DirectSignIn"] = "direct_sign_in";
    /**
     * Override the default sign-in experience configuration with the settings from the specified
     * organization ID.
     */
    ExtraParamsKey["OrganizationId"] = "organization_id";
    /**
     * Provides a hint about the login identifier the user might use.
     * This can be used to pre-fill the identifier field **only on the first screen** of the sign-in/sign-up flow.
     */
    ExtraParamsKey["LoginHint"] = "login_hint";
    /**
     * Specifies the identifier used in the identifier sign-in or identifier register page.
     *
     * This parameter is applicable only when first_screen is set to either `FirstScreen.IdentifierSignIn` or `FirstScreen.IdentifierRegister`.
     * Multiple identifiers can be provided in the identifier parameter, separated by spaces.
     *
     * If the provided identifier is not supported in the Logto sign-in experience configuration, it will be ignored,
     * and if no one of them is supported, it will fallback to the sign-in / sign-up method value set in the sign-in experience configuration.
     *
     * @see {@link SignInIdentifier} for available values.
     */
    ExtraParamsKey["Identifier"] = "identifier";
})(ExtraParamsKey || (ExtraParamsKey = {}));
/** @deprecated Use {@link FirstScreen} instead. */
export var InteractionMode;
(function (InteractionMode) {
    InteractionMode["SignIn"] = "signIn";
    InteractionMode["SignUp"] = "signUp";
})(InteractionMode || (InteractionMode = {}));
export var FirstScreen;
(function (FirstScreen) {
    FirstScreen["SignIn"] = "sign_in";
    FirstScreen["Register"] = "register";
    FirstScreen["ResetPassword"] = "reset_password";
    FirstScreen["IdentifierSignIn"] = "identifier:sign_in";
    FirstScreen["IdentifierRegister"] = "identifier:register";
    FirstScreen["SingleSignOn"] = "single_sign_on";
    /** @deprecated Use snake_case 'sign_in' instead. */
    FirstScreen["SignInDeprecated"] = "signIn";
})(FirstScreen || (FirstScreen = {}));
export const extraParamsObjectGuard = z
    .object({
    [ExtraParamsKey.InteractionMode]: z.nativeEnum(InteractionMode),
    [ExtraParamsKey.FirstScreen]: z.nativeEnum(FirstScreen),
    [ExtraParamsKey.DirectSignIn]: z.string(),
    [ExtraParamsKey.OrganizationId]: z.string(),
    [ExtraParamsKey.LoginHint]: z.string(),
    [ExtraParamsKey.Identifier]: z.string(),
})
    .partial();
