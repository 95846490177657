import type { SystemStyleInterpolation } from '@chakra-ui/styled-system';
import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const variantPrimary = defineStyle((props) => ({
  color: mode('neutral.light.7', 'neutral.light.7')(props),
}));

const variantSecondary = defineStyle((props) => ({
  color: mode('gray.500', 'gray.400')(props),
}));

const variantLight7 = defineStyle(() => ({
  _disabled: { color: 'neutral.light.5' },
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  fontStyle: 'normal',
  color: 'neutral.light.7',
}));

const variantLight6 = defineStyle(() => ({
  fontSize: '0.8125rem',
  fontWeight: 400,
  lineHeight: '1rem',
  fontStyle: 'normal',
  color: 'neutral.light.6',
}));

const variantLight5 = defineStyle(() => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  fontStyle: 'normal',
  color: 'neutral.light.5',
}));

const variantLight8 = defineStyle(() => ({
  fontSize: '1.125rem',
  fontWeight: 500,
  lineHeight: '1.75rem',
  fontStyle: 'normal',
  color: 'neutral.light.8',
}));
const variantLight1 = defineStyle(() => ({
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '1.5rem',
  fontSize: '1rem',
  color: 'neutral.light.1',
}));

const sizes = {
  8125: defineStyle({
    fontSize: '0.8125rem',
    fontWeight: 400,
    lineHeight: '1rem',
  }),
  875: defineStyle({
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
  }),
  1: defineStyle({
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.5rem',
  }),
  125: defineStyle({
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.75rem',
  }),
};

const variantInherit = {
  color: 'inherit',
};

const variants: Record<string, SystemStyleInterpolation> = {
  primary: variantPrimary,
  secondary: variantSecondary,
  inherit: variantInherit,
  light7: variantLight7,
  light6: variantLight6,
  light5: variantLight5,
  light8: variantLight8,
  light1: variantLight1,
};

const defaultProps = {
  variant: 'primary',
  alignItems: 'center',
  fontWeight: 400,
};

const Text = defineStyleConfig({
  sizes,
  defaultProps,
  variants,
});

export default Text;
