import { Box, Flex, VStack } from '@chakra-ui/react';
import AuthFooter from './AuthFooter';
import { Outlet } from 'react-router-dom';
import AuthDecor from '../../assets/auth-decor.png';

const AuthLayout = () => {
  return (
    <VStack gap={4} alignItems="flex-start" maxH={{ base: 'auto', md: '100vh' }}>
      <Flex w="full" display={{ base: 'block', md: 'flex' }}>
        <Flex
          float={{ base: 'initial', md: 'left' }}
          w={{ base: 'full', lg: '50%', xl: '50%' }}
          minH="100vh"
        >
          <Box margin="auto" maxW="348px" mx="auto" textAlign="center">
            <Outlet />
            <AuthFooter />
          </Box>
        </Flex>
        <Flex
          w={{ base: 'full', lg: '50%', xl: '50%' }}
          display={{ base: 'none', lg: 'block' }}
          float={{ base: 'initial', md: 'right' }}
          bgImage={AuthDecor}
          bgPosition="center"
          bgSize="cover"
          bgRepeat="no-repeat"
        />
      </Flex>
    </VStack>
  );
};

export default AuthLayout;
