const semanticTokens = {
  colors: {
    divider: {
      default: 'neutral.light.3',
    },
    text: {
      default: 'blackAlpha.800',
      _dark: 'whiteAlpha.800',
    },
    text_secondary: {
      default: 'gray.500',
      _dark: 'gray.400',
    },
    link: {
      default: 'accent.blue',
      _dark: 'blue.300',
    },
    // Link_hovered: {
    //   default: "blue.400",
    // },
    error: {
      default: 'red.400',
      _dark: 'red.300',
    },
  },
  shadows: {
    action_bar: '0 4px 4px -4px rgb(0 0 0 / 10%), 0 2px 4px -4px rgb(0 0 0 / 6%)',
  },
};

export default semanticTokens;
