const colors = {
  neutral: {
    light: '#A3A3A3',
    'light.1': '#FFF',
    'light.2': '#fafafa',
    'light.3': '#EDEBEC',
    'light.4': '#DEDEDE',
    'light.5': '#A3A3A3',
    'light.6': '#666666',
    'light.7': '#3D3D3D',
    'light.8': '#0A0A0A',
  },
  accent: {
    blue: '#4B66EC',
    red: '#E53E3E',
    orange: '#F08C44',
  },
  primary: {
    'light.1': '#FFEBEC',
    'light.2': '#FF949C',
    'light.3': '#FF626E',
    'light.4': '#BD0F36',
    '300': '#BD0F36',
    '500': '#FFA7AC',
    'light.border': '#FF949C',
  },
  secondary: {
    '100': '#1C6EF5',
    '200': '#FEB2B2',
    '300': '#FC8181',
    '400': '#F56565',
    '500': '#E53E3E',
    '600': '#C53030',
    '700': '#9B2C2C',
    '800': '#822727',
    '900': '#63171B',
    '1': '#1C6EF5',
    gray: '#8F8F8F',
    light: '#8160EB',
    'bg.gray': '#E4E4E4',
    'text.gray': '#626262',
    'light.bg.purple': '#F2ECF8',
    'light.text.purple': '#4B29B6',

    'light.bg.orange': '#FAF3EB',
    'light.text.orange': '#D96900',

    'light.bg.red': '#FFEEEE',

    '01': '#FAA352',
    '01.bg': '#FAF3EB',
    '01.text': '#D96900',
    '02': '#3ABD77',
    '02.text': '#02813D',
    '02.bg': '#ECF8EC',
    '03': '#8160EB',
    '03.bg': '#F2ECF8',
    '03.text': '#5C37CF',
    '04': '#3E8DF9',
    '04.text': '#0E4A9B',
    '04.bg': '#DFF6FC',
    '05': '#F45959',
    '05.text': '#DF0100',
    '05.bg': '#FFEEEE',
    '06.text': '#474747',
    '06.bg': '#F2F0F0',
    '06': '#A0A0A0',
  },
  green: {
    '100': '#C6F6D5',
    '400': '#48BB78',
    '500': '#38A169',
    '600': '#25855A',
  },
  blue: {
    '50': '#EBF8FF',
    '100': '#BEE3F8',
    '200': '#90CDF4',
    '300': '#63B3ED',
    '400': '#4299E1',
    '500': '#3182CE',
    '600': '#2B6CB0',
    '700': '#2C5282',
    '800': '#2A4365',
    '900': '#1A365D',
  },
  red: {
    '500': '#E53E3E',
    '100': '#FED7D7',
  },
  orange: {
    '100': '#FEEBCB',
    '200': '#FFFEDD',
  },
  gray: {
    '50': '#F7FAFC', // <-
    '100': '#EDF2F7',
    '200': '#E2E8F0',
    '300': '#CBD5E0',
    '400': '#A0AEC0',
    '500': '#718096',
    '600': '#4A5568',
    '700': '#2D3748',
    '800': '#3D3D3D',
    '900': '#171923',
  },
  black: '#101112',
  white: '#ffffff',
  blackAlpha: {
    '50': 'RGBA(16, 17, 18, 0.04)',
    '100': 'RGBA(16, 17, 18, 0.06)',
    '200': 'RGBA(16, 17, 18, 0.08)',
    '300': 'RGBA(16, 17, 18, 0.16)',
    '400': 'RGBA(16, 17, 18, 0.24)',
    '500': 'RGBA(16, 17, 18, 0.36)',
    '600': 'RGBA(16, 17, 18, 0.48)',
    '700': 'RGBA(16, 17, 18, 0.64)',
    '800': 'RGBA(16, 17, 18, 0.80)',
    '900': 'RGBA(16, 17, 18, 0.92)',
  },
  github: '#171923',
  telegram: '#2775CA',
  linkedin: '#1564BA',
  discord: '#9747FF',
  slack: '#1BA27A',
  twitter: '#63B3ED',
  opensea: '#2081E2',
  facebook: '#4460A0',
  medium: '#231F20',
  reddit: '#FF4500',
  primaryLightButton: 'linear-gradient(250deg, #BD0F36 19.8%, #9C163A 82.53%)',
};

export default colors;
